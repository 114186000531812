import React, {useEffect, useState} from "react";
import taskStyles from "../styles/task.module.css";
import TaskTable from "../components/tables/TaskTable";
import {Autocomplete, TextField} from "@mui/material";

const textFieldStyles = {
    "& .MuiOutlinedInput-root": {
        borderRadius: "50px",
        height: "40px",
        "& fieldset": {
            borderColor: "#3CAAC4",
        },
        "&:hover fieldset": {
            borderColor: "#3CAAC4",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#3CAAC4",
        },
    },
}

const priorities = [
    {value: "High"},
    {value: "Medium"},
    {value: "Low"},
]

const Tasks = ({project, projectId, loading, refreshInitiative}) => {

    const [selectPriority, setSelectPriority] = useState("");
    const [selectedAssigneeId, setSelectedAssigneeId] = useState("");
    const [assignees, setAssignees] = useState([]);

    const handleAssigneeSelect = (event, value) => {
        if (value) {
            setSelectedAssigneeId(value.id)
        } else {
            setSelectedAssigneeId("")
        }
    };
    const handlePrioritySelect = (event, value) => {
        if (value) {
            console.log(value)
            setSelectPriority(value.value)
        } else {
            setSelectPriority("")
        }
    };

    useEffect(() => {
        setAssignees(project.members)
    }, []);

    return (
        <div className="pt-4 w-100 overflow-hidden">

            <div className="d-flex flex-column flex-md-row column-gap-3">
                <div className="col-lg-4 col-md-5">
                    <span className="form-label">Assignee</span>
                    <Autocomplete
                        className="w-100 mt-1"
                        disablePortal
                        options={assignees}
                        onChange={handleAssigneeSelect}
                        getOptionLabel={(option) => option?.name || ""}
                        renderInput={(params) => (
                            <TextField
                                placeholder="Select Assignee"
                                className={`custom-dropdown ${taskStyles.filterDropdownButton}`}
                                {...params}
                                sx={textFieldStyles}
                            />
                        )}
                    />

                </div>
                <div className="col-lg-4 col-md-5">
                    <span className="form-label">Priority</span>
                    <Autocomplete
                        className="w-100 mt-1"
                        disablePortal
                        options={priorities}
                        onChange={handlePrioritySelect}
                        getOptionLabel={(option) => option?.value || ""}
                        renderInput={(params) => (
                            <TextField
                                placeholder="Select Assignee"
                                className={`custom-dropdown ${taskStyles.filterDropdownButton}`}
                                {...params}
                                sx={textFieldStyles}
                            />
                        )}
                    />
                </div>

            </div>

            <div className={`mt-4`}>
                <TaskTable project_id={projectId} priority={selectPriority} assignee={selectedAssigneeId} />
            </div>

        </div>
    )
}

export default Tasks
