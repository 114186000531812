import React, { useContext, useEffect, useState } from "react";
import CustomizedSteppers from "../components/CustomizedSteppers";
import { Container } from "react-bootstrap";
import CustomButton from "../components/CustomButton";
import useFetch from "../hooks/useFetch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "../styles/Home.css";
import CheckBoxForm from "../components/forms/CheckBoxForm";
import sparkles from "../assets/sparkles.png";
import {
  getPaperInsights,
  submitPreChecks,
  updateInsight,
  updatePaperStatus,
  updatePostCheck,
} from "../api/Api";
import { showToast } from "../utils/toastUtils";
import ReferenceResultCard from "../components/cards/ReferenceResultCard";
import CircularIndeterminate from "../components/CircularIndeterminate";
import { PaperContext } from "../contexts/PaperContextProvider";
import TextModel from "../components/modals/TextModel";

function Preview() {
  const [activeStep, setActiveStep] = React.useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const upload = searchParams.get("upload");
  const { paperId, journalId } = useParams();

  const {
    data: PreIssues,
    error,
    loading,
  } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/journals/${journalId}/prechecks`
  );
  const {
    data: PostIssues,
    error: error1,
    loading: loading1,
  } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/papers/${paperId}/postchecks/`
  );

  useEffect(() => {
    if (upload) setActiveStep(0);
  }, [upload]);

  if (loading || loading1) return <CircularIndeterminate />;
  if (error || error1) return <div>{error}</div>;

  return (
    <>
      <Container style={{ maxWidth: "960px" }}>
        <div className="my-5">
          <CustomizedSteppers
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
        <div>
          {activeStep === 0 && (
            <PreCheck
              setActiveStep={setActiveStep}
              PreIssues={PreIssues}
              journalId={journalId}
            />
          )}
          {activeStep === 1 && (
            <Insights
              setActiveStep={setActiveStep}
              paperId={paperId}
              isReady={false}
            />
          )}
          {activeStep === 2 && <PostCheck PostIssues={PostIssues} />}
        </div>
      </Container>
    </>
  );
}

export default Preview;

const PreCheck = ({ setActiveStep, PreIssues, journalId }) => {
  const { paperId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // Initialize state to track checkbox statuses
  const [checkedIssues, setCheckedIssues] = useState(
    PreIssues.reduce((acc, issue) => {
      acc[issue.id] = false; // Set default checked state as false
      return acc;
    }, {})
  );

  const handlePreCheckChange = (issueId, checked) => {
    setCheckedIssues((prevCheckedIssues) => ({
      ...prevCheckedIssues,
      [issueId]: checked,
    }));
  };

  const handlePreCheckSubmit = async () => {
    if (!isLoading) {
      console.log(checkedIssues);
      setIsLoading(true);
      const payload = {
        paperId,
        question: checkedIssues,
      };

      submitPreChecks(journalId, payload).then((response) => {
        console.log(response);
        if (response.status === 200) {
          setActiveStep(1);
          showToast(
            response?.message ||
              "Your paper has been successfully sent for review!",
            "success"
          );
        } else {
          showToast(response.message || "Something went wrong", "error");
        }
      });
    }
  };

  return (
    <div className="text-center m-0 mt-3 m-sm-5">
      <h2>Tell us a bit more about this paper</h2>
      <div>
        <CheckBoxForm
          Issues={PreIssues}
          checkedIssues={checkedIssues}
          handleCheckChange={handlePreCheckChange}
          style={{ textAlign: "start" }}
        />
      </div>
      <CustomButton className="rounded-pill m-4" onClick={handlePreCheckSubmit}>
        {isLoading ? "Submitting..." : "Submit To Review"}
      </CustomButton>
    </div>
  );
};

const Insights = ({ setActiveStep, array, paperId }) => {
  const [isReady, setIsReady] = useState(false);
  const [insights, setInsightIssues] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [checkedIssues, setCheckedIssues] = useState([]);

  useEffect(() => {
    getPaperInsights(paperId).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setIsReady(true);
        setInsightIssues(response.data.insights_list);
        setReferenceList(response.data.reference_results);
        setCheckedIssues(
          response.data.insights_list.reduce((acc, issue) => {
            acc[issue.id] = issue.is_checked || false;
            return acc;
          }, {})
        );
      }
      if (response.status === 202) {
        setIsReady(false);
      }
    });
  }, [paperId]);

  console.log(checkedIssues);
  const handleCheckChange = (insight_id, is_checked) => {
    const payload = {
      insight_id: insight_id,
      is_checked: is_checked,
    };
    console.log(payload);

    updateInsight(paperId, payload).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setCheckedIssues((prevCheckedIssues) => ({
          ...prevCheckedIssues,
          [insight_id]: is_checked,
        }));
      } else {
        showToast(response.message || "Something went wrong", "error");
      }
    });
  };

  // should be dynamic
  if (!array)
    array = [
      { title: "Home", link: "/" },
      { title: "Preview", link: "/preview" },
      { title: "Insights", link: "/preview/insights" },
    ];

  if (!isReady) {
    return (
      <div className="text-center m-lg-5 m-md-3 m-2">
        <div>
          <h2>Your paper is being reviewed</h2>
          <p>We will notify you via email after the review is completed.</p>
        </div>

        <CustomButton
          className="rounded-pill m-4"
          onClick={() => setActiveStep(2)}
        >
          Next
        </CustomButton>
      </div>
    );
  }
  return (
    <div className="m-2 m-sm-5">
      <BreadCrumb array={array} />
      <AlertMessage className="mb-5" />
      <h2>
        Insights <img width={40} src={sparkles} alt="sparkles" />
      </h2>
      <p className="text-secondary">
        You've strayed from a few of the Lancet journal's guidelines. Below,
        you'll find a list of these discrepancies. By addressing them, you can
        ensure your articles are primed and polished for submission to the
        Lancet journal.
      </p>

      <CheckBoxForm
        Issues={insights}
        checkedIssues={checkedIssues}
        handleCheckChange={handleCheckChange}
        style={{ margin: 0 }}
      />

      <div className={"my-2"}>
        {referenceList.map((result, index) => (
          <ReferenceResultCard key={index} result={result} />
        ))}
      </div>

      <div className="text-center">
        <CustomButton
          className="rounded-pill m-4"
          onClick={() => setActiveStep(2)}
        >
          Next
        </CustomButton>
      </div>
    </div>
  );
};

// Post check component
const PostCheck = ({ PostIssues }) => {
  const navigate = useNavigate();
  const { paperId } = useParams();
  const { data: paper, refresh } = useContext(PaperContext);
  const [openDialog, setOpenDialog] = useState(false); // dialog box state for status change
  // Initialize state to track checkbox statuses
  const [checkedIssues, setCheckedIssues] = useState(
    PostIssues?.reduce((acc, issue) => {
      acc[issue.id] = issue.answer; // Set default checked state as false
      return acc;
    }, {})
  );

  const handleCheckChange = (issueId, checked) => {
    const payload = {
      question_id: issueId,
      value: checked,
    };

    updatePostCheck(paperId, payload).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setCheckedIssues((prevCheckedIssues) => ({
          ...prevCheckedIssues,
          [issueId]: checked,
        }));
      } else {
        showToast(response.message || "Something went wrong", "error");
      }
    });
  };

  // const paper.status = "Draft";
  const handleStatusChange = () => {
    updatePaperStatus(paperId, null, "Ready for Submission")
      .then((response) => {
        if (response.status === 200) {
          setOpenDialog(true);
          refresh();
        } else {
          showToast(response.message || "Something went wrong", "error");
        }
      })
      .catch((error) => {
        showToast(error.message || "Something went wrong", "error");
      });
  };

  const handleDialogClose = () => setOpenDialog(false);

  return (
    <div className="m-3 m-md-5">
      <h2>Post-Checks</h2>
      <AlertMessage />
      <div>
        <CheckBoxForm
          Issues={PostIssues}
          checkedIssues={checkedIssues}
          handleCheckChange={handleCheckChange}
          style={{ textAlign: "start" }}
        />
      </div>
      <div className="text-center">
        <CustomButton
          className="rounded-pill m-4"
          onClick={() => navigate(`/paper/${paperId}`)}
        >
          View Paper
        </CustomButton>
      </div>
      {/* this button shows only when status === Draft */}
      {paper.status === "Draft" && (
        <div className="text-center">
          <CustomButton
            className="rounded-pill mb-4"
            onClick={handleStatusChange}
          >
            Mark as Ready for Submission
          </CustomButton>
        </div>
      )}

      {/* popup model for status change */}
      <TextModel content="Paper was marked as ready for submission" open={openDialog} setClose={handleDialogClose} />
    </div>
  );
};

// Alert message component
const AlertMessage = ({ className = "" }) => {
  return (
    <div
      style={{ backgroundColor: "#FFF1C2" }}
      className={`p-3 w-100 my-3 ${className} d-flex`}
    >
      <WarningAmberIcon style={{ width: "30px" }} className="me-3" />
      <p className="m-0">
        We do not guarantee that fixing below issues alone will lead to the
        journal accepting your article.
      </p>
    </div>
  );
};
