import TaskComment from "./cards/TaskComment";
import React, {useEffect, useState} from "react";
import {BiSolidSend} from "react-icons/bi";
import styles from "./tables/table.module.css";
import {createTaskComment, getAllTaskComments} from "../api/Api";

const TaskComments = ({options, stringToColor, loadAll, close}) => {
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        loadAllMessages()
    }, []);

    const handleMessageOnChange = (e) => {
        console.log(e.target.value);
        setMessage(e.target.value)
    }
    const handleMessageOnKeyEnter = (e) => {
        console.log(e.key);

        if (e.key === 'Enter') {
            sendComment()
        }
    }

    const loadAllMessages = () => {

        getAllTaskComments(options.id).then(res => {
            console.log(res)
            setComments(res.data)
        })
    }

    const sendComment = () => {

        if (message.trim() !== '') {
            const obj = {
                content:message
            }

            createTaskComment(options.id, obj).then(res => {
                if (res.status === 201) {
                    loadAllMessages()
                    loadAll()
                    setMessage("")
                }
            })
        }
    }

    return (
        <div className="h-100 d-flex flex-column">

            <div className="py-3 px-3 border-bottom sticky-top d-flex justify-content-between align-items-center">
                <span className="fw-semibold pe-2">{options.title}</span>

                <button onClick={close} className="btn btn-close"></button>
            </div>

            <div className={`flex-grow-1 overflow-auto py-2 ps-3 ${styles.styledScroll}`}>
                <div>
                    <div className="overflow-auto mt-4">
                        {comments.map((item, index) => (
                            <div key={index}>
                                <TaskComment comment={item} stringToColor={stringToColor} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="py-2 pb-5 px-3 border-top sticky-bottom">
                <div className="d-flex align-items-center w-100">
                    <input
                        type="text"
                        className={`form-control me-2 rounded-pill ${styles.commentInput}`}
                        placeholder="Type something..."
                        value={message}
                        onChange={handleMessageOnChange}
                        onKeyDown={handleMessageOnKeyEnter}
                    />
                    <button onClick={sendComment} className={`${styles.commentButton} btn btn-primary d-flex justify-content-center align-items-center h-100`}>
                        <BiSolidSend/>
                    </button>
                </div>
            </div>

        </div>
    )
}

export default TaskComments
