import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { ModelContext } from "../../contexts/ModelContextProvider";
import { useFormik } from "formik";
import { RegisterSchema } from "../../utils/validations";

const RegisterForm = () => {
  const { setClose, VerifyAlertOpen } = useContext(ModelContext);
  const { register, addUserInfo } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      handleRegisters(values);
    },
  });

  // Handle registration
  const handleRegisters = async (values) => {
    setError(null);
    setLoading(true);

    const { email, password, firstName, lastName } = values;
    const payload = {
      email,
      password,
      firstname: firstName,
      lastname: lastName,
    };

    try {
      const data = await register(payload);
      if (data.status === 200) {
        addUserInfo(data.data);
        setClose();
        VerifyAlertOpen();
      } else {
        setError(data?.message);
      }
    } catch (error) {
      setError("An error occurred during registration.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 p-md-0">
      <h1 className="mb-4 text-center">Sign Up</h1>
      {/* Display error message */}
      {error && <div className="alert alert-danger">{error}</div>}{" "}
      <form id="register-form" onSubmit={formik.handleSubmit}>
        <Container fluid className="px-3 py-4 p-md-0">
          <Row className="mt-md-3">
            <Col xs={12} md={6}>
              <label htmlFor="firstName" className="form-label h6">
                First Name
              </label>
              <input
                type="text"
                placeholder="First Name"
                className={`form-control mb-0 ${
                  formik.touched.firstName && formik.errors.firstName
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="invalid-feedback">
                  {formik.errors.firstName}
                </div>
              )}
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="lastName" className="form-label h6">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className={`form-control mb-0 ${
                  formik.touched.lastName && formik.errors.lastName
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("lastName")}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="invalid-feedback">{formik.errors.lastName}</div>
              )}
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col xs={12}>
              <label htmlFor="email" className="form-label h6">
                E-mail
              </label>
              <input
                type="email"
                placeholder="Email"
                className={`form-control mb-0 ${
                  formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col xs={12} md={6}>
              <label htmlFor="password" className="form-label h6">
                Password
              </label>
              <input
                type="password"
                placeholder="Password"
                className={`form-control mb-0 ${
                  formik.touched.password && formik.errors.password
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback">{formik.errors.password}</div>
              )}
            </Col>
            <Col xs={12} md={6}>
              <label htmlFor="confirmPassword" className="form-label h6">
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="Confirm Password"
                className={`form-control mb-0 ${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "is-invalid"
                    : ""
                }`}
                {...formik.getFieldProps("confirmPassword")}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </Col>
          </Row>
          <button
            type="submit"
            className="btn w-100 my-4 btn-submit border-0"
            disabled={loading || formik.isSubmitting}
          >
            {loading ? "Loading..." : "Next"}
          </button>
          <p className="small mt-4 text-center">
            By signing up with Hyvv, you are confirming that you have read,
            understood, and accepted our{" "}
            <a href="https://www.thehyvv.com/terms-and-conditions">
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="https://www.thehyvv.com/privacy-policy">Privacy Policy</a>.
          </p>
        </Container>
      </form>
    </div>
  );
};

export default RegisterForm;
