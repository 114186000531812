/**
 * The function `PaperCard` returns a JSX element representing a card component displaying details of a
 * paper with options for deletion.
 * @param paper - The `PaperCard` function takes a `paper` object as a parameter. The `paper` object
 * likely contains information about a paper document, such as its `id`, `date_uploaded`, `name`, and
 * `description`. The function then renders a card component displaying details of the paper, including
 * @returns The PaperCard function returns a JSX element that consists of a Stack component containing
 * a Card component displaying details of a paper, along with a FadeMenu component for additional
 * options like deleting the paper.
 */

import React from "react";
import { Card, Stack } from "react-bootstrap";
import FadeMenu from "../FadeMenu";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../CustomSkeleton";

/** 
  *handleMenuItemClick is a function that takes a two parameter,`item` and `value` ,
    which is the id of the paper value is normally the id of the paper
**/
function PaperCard({loading, paper, handleMenuItemClick, projectId, initiativeId }) {
  const navigate = useNavigate();

  // Set the navigation URL based on conditions
  const navigationUrl =
    paper.id && projectId && initiativeId
      ? `/project/${projectId}/initiative/${initiativeId}/journals/${paper.journal.id}/preview/${paper.id}`
      : paper.id
      ? `/paper/${paper.id}`
      : "";

  // Handle the card click event
  const handleCardClick = () => {
    if (navigationUrl) navigate(navigationUrl);
  };


  return (
    <Stack
      id="paper-details-card"
      key={paper.id}
      direction="horizontal"
      gap={0}
      style={{ cursor: "pointer" }}
    >
      <Card
        onClick={handleCardClick}
        className="my-2 border-0 border-bottom rounded-0 w-100"
      >
        <Card.Body>
          {paper.date_uploaded && (
            <Card.Subtitle className="my-2">
              {loading? <CustomSkeleton style={{ maxWidth: "6rem" }}/>:`${new Date(paper.date_uploaded).toLocaleString()} `}
              {/* {`${paper.date_uploaded} ${paper.status} `} */}
              {/* to ${paper.owner.name}*/}
            </Card.Subtitle>
          )}
          <Card.Title className="text-break">{loading? <CustomSkeleton style={{ maxWidth: "10rem" }}/>: paper.name.replace(".docx", "")}</Card.Title>
          <Card.Text>{loading? <CustomSkeleton count={3}/>: paper.description}</Card.Text>
        </Card.Body>
      </Card>
      <FadeMenu
        buttonLabel="..."
        menuItems={["Delete"]}
        onMenuItemClick={handleMenuItemClick}
        value={paper.id}
      />
    </Stack>
  );
}

export default PaperCard;
