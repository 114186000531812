import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import React, {useContext, useEffect, useState} from "react";
import {useFormik} from "formik";
import {TaskCreateUpdateSchema} from "../../utils/validations";
import {Col, Row} from "react-bootstrap";
import {Autocomplete, TextField} from "@mui/material";
import modelStyles from "../../styles/custom-model.module.css"
import {InitiativeContext} from "../../contexts/InitiativeContextProvider";
import {createTask, updateTask} from "../../api/Api";
import {showToast} from "../../utils/toastUtils";

const priorities = [
    {name:"High", value:"High"},
    {name:"Medium", value:"Medium"},
    {name:"Low", value:"Low"}
]


const dropdownStyles ={
    "& .MuiOutlinedInput-root": {
        height: "40px",
    }
}


function AddUpdateTask({show, handleClose, projectId, is_new_task = true, task, loadAll, setSubtask}) {
    const { refresh, data, loading } = useContext(InitiativeContext);
    const [assignees, setAssignees] = useState([]);
    const [defaultUser, setDefaultUser] = useState(null);
    const [openSubPopup, setOpenSubPopup] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: is_new_task ? "" : task.title,
            priorities: !is_new_task && task.priority ? {name:task.priority} : null,
            assignee: "",
        },
        validationSchema: TaskCreateUpdateSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            handleSubmit(values)
        },
    });

    const handleSubmit = (values) => {
        if (is_new_task) {
            let obj = {
                title: values.title,
                priority: values.priorities ? values.priorities.name : null,
                assignee: values.assignee ? values.assignee.id : null,
                project_id: projectId
            }

            console.log(obj)
            createTask(obj).then(res => {
                if (res.status === 200) {
                    showToast("Goal created successfully", "success")
                    loadAll()
                    if (openSubPopup) setSubtask(res.data)
                    handleClose()
                }
            })

        } else {
            let obj = {
                title: values.title,
                priority: values.priorities ? values.priorities.name : null,
                assignee: values.assignee ? values.assignee.id : null,
            }

            // call the API
            updateTask(task.id, obj).then(res=>{
                if (res.status === 200) {
                    showToast("Goal updated successfully", "success")
                    loadAll()
                    handleClose()
                }
            })
        }
    }

    useEffect(() => {
        if (data.members) {
            setAssignees(data.members)
        }

        if (task && !is_new_task && task.assignee && task.assignee.id) {
            const matchedUser = data.members.find((item) => item.id === task.assignee.id);

            if (matchedUser) {
                formik.setFieldValue("assignee", matchedUser);
            }
        }

    }, []);

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <form onSubmit={formik.handleSubmit}
                  id="create-update-task-form"
                  className=""
            >
                <Modal.Header style={{borderBottom: 0}} closeButton></Modal.Header>
                <Modal.Title style={{textAlign: "center", marginBottom: "20px"}}>
                    {is_new_task ? "Add New Goal" : "Update Goal"}
                </Modal.Title>
                <Modal.Body>
                    <Row>
                        <Col md={12}>

                            <label htmlFor="email" className="form-label text-color-tertiary">
                                <p className="small mb-0">Goal title</p>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter goal title"
                                name="title"
                                className={`${modelStyles.fields} form-control ${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                {...formik.getFieldProps('title')}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className="invalid-feedback">{formik.errors.title}</div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label htmlFor="priorities" className="form-label text-color-tertiary">
                                <p className="small mb-0">Priorities</p>
                            </label>
                            <Autocomplete
                                className={`py-0`}
                                disablePortal
                                options={priorities}
                                value={formik.values.priorities}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={
                                            formik.touched.priorities && Boolean(formik.errors.priorities)
                                        }
                                        helperText={formik.touched.priorities && formik.errors.priorities}
                                        sx={dropdownStyles}
                                        placeholder="Select priority"
                                    />
                                )}
                                onChange={(event, value) =>
                                    formik.setFieldValue("priorities", value)
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="priorities" className="form-label text-color-tertiary">
                                <p className="small mb-0">Assignees</p>
                            </label>
                            <Autocomplete
                                className="py-0"
                                disablePortal
                                options={assignees}
                                value={formik.values.assignee}
                                getOptionLabel={(option) => option?.name || ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={
                                            formik.touched.assignee && Boolean(formik.errors.assignee)
                                        }
                                        helperText={formik.touched.assignee && formik.errors.assignee}
                                        sx={dropdownStyles}
                                        placeholder="Select assignee"
                                    />
                                )}
                                onChange={(event, value) =>
                                    formik.setFieldValue("assignee", value)
                                }
                            />
                        </Col>

                    </Row>

                </Modal.Body>
                <Modal.Footer className="border-0" style={{marginBottom: "30px"}}>

                    <button
                        onClick={()=>setOpenSubPopup(true)}
                        type="submit"
                        className={`${is_new_task ? '' : 'd-none'} btn btn-outline-danger ${modelStyles.customSecondaryOutlineBtn} font-weight-bold rounded-pill`}
                    >
                        Save & Add Subtask
                    </button>

                    <CustomButton rounded={true} type="submit">
                        Save
                    </CustomButton>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddUpdateTask;
