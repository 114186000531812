import {Avatar} from "@mui/material";
import styles from "../tables/table.module.css";
import React, {useEffect} from "react";

const TaskComment = ({comment, stringToColor}) => {

    useEffect(() => {
        console.log(comment);
    }, []);

    return (
        <div className="d-flex column-gap-2 mb-3">
            <div>
                <Avatar
                    alt={`${comment.sender.first_name} ${comment.sender.last_name}`}
                    src={" "}
                    sx={{
                        fontSize: 14,
                        width: 30,
                        height: 30,
                        bgcolor: stringToColor(comment.sender.first_name),
                    }}
                />
            </div>
            <div>
                <span className={`fw-semibold text-color-tertiary ${styles.commentTitle}`}>{`${comment.sender.first_name} ${comment.sender.last_name}`}</span>
                <p className={`${styles.commentBody}`}>{comment.content}</p>
            </div>
        </div>
    )
}

export default TaskComment
