import * as Yup from "yup";

// ** Regex patterns
// const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/;


// ** User extra details form validation schema
export const UserDetailsSchema = Yup.object().shape({
    username: Yup.string()
        .min(4, "Username is too short!")
        .max(20, "Username is too long!")
        .required("Username is required"),

    website: Yup.string()
        .url("Please enter a valid URL, e.g., https://example.com or http://example.com")
        .required("Website is required"),

    /*website: Yup.string()
        .matches(urlRegex, "Please enter a valid URL")
        .required("Website is required"),*/

    jobTitle: Yup.string()
        .min(2, "Job title is too short!")
        .max(50, "Job title is too long!")
        .required("Job title is required"),

    company: Yup.string()
        .min(2, "Company name is too short!")
        .max(50, "Company name is too long!")
        .required("Company name is required"),

    aboutYou: Yup.string()
        .max(500, "About you section is too long!")
        .required("About you is required"),

    institute_name: Yup.string()
        .max(50, "Institute name is too long!"),
});

// ** project update form validation schema
export const ProjectUpdateSchema = Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
    projectDescription: Yup.string().required("Project description is required"),
});

// ** Register form validations schema
export const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});

// ** Login form validation schema
export const LoginSchema = Yup.object().shape({
    email:Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required('Password is required'),
})

// ** task create/update form validation schema
export const TaskCreateUpdateSchema = Yup.object().shape({
    title: Yup.string()
        .required("Task title is required")
        .max(100, "Task title must not exceed 100 characters"),
});