import { Tooltip, styled } from "@mui/material";
import { useEffect, useState } from "react";

// Custom Tooltip styling
const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "#4a4a4a",
        color: "#ffffff",
        fontWeight: "bold",
        fontSize: "0.9rem",
        borderRadius: "8px",
        padding: "8px 12px",
    },
    [`& .MuiTooltip-arrow`]: {
        color: "#4a4a4a",
    },
}));

export const RAGStatus = () => {
    const [status, setStatus] = useState("");


    const setStatusColor = (type) => {
        switch (type) {
            case "Critical":
                return "#DC3545";
            case "Warning":
                return "#FFC107";
            case "On-track":
                return "#28A745";
            default:
                return "#28A745";
        }
    };

    const getRandomStatus = () => {
        const statuses = ["Critical", "Warning", "On-track"];
        const randomIndex = Math.floor(Math.random() * statuses.length);
        return statuses[randomIndex];
    };

    // Set a random status when the component mounts
    useEffect(() => {
        const randomStatus = getRandomStatus();
        setStatus(randomStatus);
    }, []);

    return (
        <div>
            <StyledTooltip title={status} arrow placement="top">
                <div
                    className="rag-circle"
                    style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: setStatusColor(status),
                        borderRadius: "50%",
                        cursor: "pointer",
                    }}
                ></div>
            </StyledTooltip>
        </div>
    );
};