import React, { useContext, useState } from "react";
import { Card, Dropdown, DropdownButton, Stack } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import "../styles/Home.css";
import CustomButton from "../components/CustomButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import InnerLayout from "../layout/InnerLayout";
import AddNewArticle from "../components/modals/AddNewArticle";
import useFetch from "../hooks/useFetch";
import FadeMenu from "../components/FadeMenu";
import { deleteInitiative, deletePaper } from "../api/Api";
import { InitiativeContext } from "../contexts/InitiativeContextProvider";
import { showToast } from "../utils/toastUtils";
import PaperCard from "../components/cards/PaperCard";
import DeleteModel from "../components/modals/DeleteModel";
import img from "../assets/emptybox.png";
import CustomSkeleton from "../components/CustomSkeleton";
import PaperCardSkeleton from "../components/skeleton/PaperCardSkeleton";

export default function Initiative() {
  return (
    <InnerLayout
      children1={<ContentComponent />}
      children2={<RightSideMenu />}
    />
  );
}

function ContentComponent() {
  const navigate = useNavigate();
  const { initiativeId, projectId } = useParams();
  const [selectedItem, setSelectedItem] = useState("All");
  const [showAddArticle, setShowAddArticle] = useState(false);
  const [modalType, setModalType] = useState("Paper"); // paper or initiative
  const [deleteModelPaperId, setDeleteModelPaperId] = useState(null); // paper or initiative
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get("name");
  const { refresh: refreshProjectInitiatives } = useContext(InitiativeContext);

  const {
    data: initiative,
    error,
    loading,
    refresh,
  } = useFetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/initiatives/${initiativeId}`); //get initiative by id

  const handleSelect = (eventKey) => setSelectedItem(eventKey);
  const handleNewArticle = () => setShowAddArticle(true);
  const openAlert = () => setOpen(true); // for open initiative delete model
  const closeAlert = () => setOpen(false); // for close initiative delete model

  const handleMenuItemClick = async (item, value) => {
    // for paper FadeMenu
    if (item === "Delete") setDeleteModelPaperId(value);
    setModalType("Paper");

    //for initiative FadeMenu
    if (item === "Delete Initiative") setModalType("Initiative");

    openAlert();
  };

  // delete function in the DialogBox model initiative deletion
  const handleDeleteInitiative = (InitiativeId) => {
    console.log(InitiativeId);
    deleteInitiative(InitiativeId).then((response) => {
      if (response?.status === 200) {
        refreshProjectInitiatives();
        navigate(`/project/${projectId}`, { replace: true });
      }
      else showToast(response.message, "error");
    }).catch((error) => {
      showToast(error.response?.data?.message || "Error loading data", "error");
    });
    closeAlert();
  };

  // delete function in the DialogBox model for paper deletion
  const handleDeletePaper = (paperId) => {
    console.log(paperId);
    deletePaper(paperId).then((response) => {
      if (response?.status === 200) refresh();
      else showToast(response.message, "error");
    }).catch((error) => {
      showToast(error.response?.data?.message || "Error loading data", "error");
    });
    closeAlert();
  };

  
  if (error) return <div>{error}</div>;

  const array = [
    { title: projectName, link: `http://localhost:3000/project/${projectId}` },
    { title: initiative?.name, link: "/" },
  ];

  return (
    <div id="content-component">
      <Stack direction="horizontal">
        <BreadCrumb array={array} />
        <FadeMenu
          className="ms-auto"
          buttonLabel="..."
          menuItems={["Delete Initiative"]}
          onMenuItemClick={handleMenuItemClick}
          value={initiativeId}
        />
      </Stack>
      <h5
        style={{ fontWeight: 400, fontSize: "17px", lineHeight: "25px" }}
        className="py-3 py-md-4 py-xxl-5"
      >
        {loading ? <CustomSkeleton count={5} /> : initiative?.description}
      </h5>

      <div className="d-flex flex-column flex-lg-row">
        <Stack direction="horizontal" gap={3}>
          <DropdownButton
            className="custom-dropdown rounded-pill text-capitalize"
            style={{ backgroundColor: "transparent" }}
            title={selectedItem}
            onSelect={handleSelect}
          >
            <Dropdown.Item eventKey="all">All</Dropdown.Item>
            <Dropdown.Item eventKey="submit">Submit</Dropdown.Item>
          </DropdownButton>
          <div>Clear Filters</div>
        </Stack>

        <CustomButton
          className={"ms-auto my-4 my-md-0"}
          rounded={true}
          onClick={handleNewArticle}
          pre={<AddRoundedIcon sx={{ width: 20 }} />}
        >
          NEW ARTICLE
        </CustomButton>
      </div>
      <AddNewArticle
        show={showAddArticle}
        handleClose={setShowAddArticle}
        initiativeId={initiativeId}
        projectId={projectId}
      />

      {loading ? (
        <PaperCardSkeleton n={3}/>
      ) : (
        initiative?.papers.map((paper) => (
          <PaperCard
            loading={loading}
            paper={paper}
            handleMenuItemClick={handleMenuItemClick}
            projectId={projectId}
            initiativeId={initiativeId}
          />
        ))
      )}

      {initiative?.papers.length === 0 ? (
        <div className="w-100 d-flex flex-column align-items-center mt-md-5 mt-3 h-100">
          <p className="text-center text-color-tertiary fst-italic fs-6 mb-0">
            No research papers available.
            <br /> You can add a new paper by clicking on the ‘New Article’
            button above.
          </p>

          <img
            src={img}
            className="opacity-25"
            alt=""
            style={{ width: "150px" }}
          />
        </div>
      ) : (
        ""
      )}
      <DeleteModel
        open={open && modalType === "Paper"}
        closeAlert={closeAlert}
        onClick={() => handleDeletePaper(deleteModelPaperId)}
        type={modalType}
      />
      <DeleteModel
        open={open && modalType === "Initiative"}
        closeAlert={closeAlert}
        onClick={() => handleDeleteInitiative(initiativeId)}
        type={modalType}
      />
    </div>
  );
}

function RightSideMenu({ projectArray }) {
  return (
    <Card className="rounded-0 border-0 mt-5">
      <Card.Body>
        <Card.Title>{""}</Card.Title>
        <Card.Text>
          {projectArray?.map((project) => (
            <Card key={project.id} className=" border-0">
              <Card.Body>
                <Card.Title>{project.name}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}



