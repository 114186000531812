import axios from 'axios';
import client from './client';
import {showToast} from "../utils/toastUtils";

// Set the base URL for the API
const api = client();
const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

console.log(process.env)

// Function to get data (GET request)
export const getData = async (endpoint) => {
    try {
        const response = await api.get(endpoint);
        return response.data;
    } catch (error) {
        // console.error('Error fetching data:', error);
        showToast(error.response?.data?.message || "Error loading data", "error");
    }
};

// Function to create new data (POST request)
export const postData = async (endpoint, payload) => {
    try {
        const response = await api.post(endpoint, payload);
        return response.data;
    } catch (error) {
        console.error('Error creating data:', error);
        if (error.response?.status === 403) {
            showToast("You are not authorized to perform this action", "error");
        } else {
            showToast(error.response?.data?.message || "Error deleting data", "error");
        }
        return error.response?.data;
    }
};

// Function to update data (PUT request)
export const putData = async (endpoint, payload) => {
    try {
        const response = await api.put(endpoint, payload);
        return response.data;
    } catch (error) {
        console.error('Error creating data:', error);
        if (error.response?.status === 403) {
            showToast("You are not authorized to perform this action", "error");
        } else {
            showToast(error.response?.data?.message || "Error deleting data", "error");
        }
        return error.response?.data;
    }
};
// Function to update data (PATCH request)
export const patchData = async (endpoint, payload) => {
    try {
        const response = await api.patch(endpoint, payload);
        return response.data;
    } catch (error) {
        console.error('Error creating data:', error);
        if (error.response?.status === 403) {
            showToast("You are not authorized to perform this action", "error");
        } else {
            showToast(error.response?.data?.message || "Error deleting data", "error");
        }
        return error.response?.data;
    }
};

export const deleteData = async (endpoint) => {
    try {
        const response = await api.delete(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error creating data:', error);
        if (error.response?.status === 403) {
            showToast("You are not authorized to perform this action", "error");
        } else {
            showToast(error.response?.data?.message || "Error deleting data", "error");
        }
        return error.response?.data;
    }
};

//get all unassigned papers
export const getReviewerUnAssignedPapers = async () => {
    const response = await getData('/reviews/unassigned/');
    return response;
}

//get all assigned papers
export const getReviewerAssignedPapers = async () => {
    const response = await getData('/reviews/assigned/');
    return response;
}

//get review paper by id
export const getReviewPaper = async (paperId) => {
    const response = await getData(`/papers/${paperId}`);
    return response;
}

//submit review paper
export const submitReviewPaper = async (id,payload) => {
    const response = await postData(`/reviews/paper/${id}/`, payload);
    return response;
}

// get all papers to reviewer
export const assignToMe = async (paperId) => {
    const response = await postData(`/reviews/paper/${paperId}/assign` );
    return response;
}

// get all questions to reviewer
export const getReviewQuestions = async (journalId) => {
    const response = await getData(`journals/${journalId}/reviewquestions` ); //todo: change 5 to dynamic
    return response;
}

// submit extra user details 
export const submitExtraUserDetails = async (payload) => { 
    const token = localStorage.getItem("token")
    const response = await axios.put(`${API_BASE_URL}/users/profile-details/`,payload,{
    headers: {
        'Authorization': `Bearer ${token}`,
    }});
    return response;
}

// get all institutes
export const getInstitutes = async () => {
    const response = await getData('/users/institutes/');
    return response;
}

// get all countries
export const getCountries = async () => {
    const response = await getData('/users/countries/');
    return response;
}



// get user details
export const getUserProfile = async () => {
    const response = await axios.get(`${API_BASE_URL}/users/profile-details/`,{
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    });
    return response;
}

// get user activities
export const getUserActivities = async () => {
    const response = await getData('/users/activities/');
    return response;
}

// get user activities
export const getJournals = async () => {
    const response = await getData('/journals/');
    return response;
}

// upload paper (used in dropzone)
export const uploadPaper = async (payload) => {
    const response = await postData(`/papers/`, payload);
    return response;
}

// get paper insights
export const getPaperInsights = async (paperId) => {
    const response = await getData(`/reviews/paper/${paperId}/`);
    return response;
}

// to update insight checkbox click
export const updateInsight = async (paperId,payload) => {
    const response = await patchData(`reviews/paper/${paperId}/` ,payload);
    return response;
}

// get papers by their paper id
export const getPaperById = async (paperId) => {
    const response = await getData(`/papers/${paperId}`);
    return response;
}

export const getMyAllPapers = async () => {
    const response = await getData(`/papers/`);
    return response;
}

export const deletePaper = async (paperId) => {
    const response = await deleteData(`/papers/${paperId}`);
    return response;
}

export const uploadSupplementaryMaterials = async (paperId) => {
    const response = await postData(`/papers/${paperId}/supplementary-materials`);
    return response;
}

export const deleteSupplementaryMaterial = async (paperId,Material_id) => {
    const response = await deleteData(`papers/${paperId}/supplementary-materials/${Material_id}/`);
    return response;
}

// submit checkbox values to backend
export const submitPreChecks = async (journalId,payload) => { 
    const response = await putData(`journals/${journalId}/prechecks` ,payload);
    return response;
}

// submit checkbox values to backend
export const updatePostCheck = async (paperId,payload) => { 
    const response = await putData(`papers/${paperId}/postchecks/` ,payload);
    return response;
}

// Get all notifications
export const getAllUserNotifications = async () => {
    const response = await getData(`/users/notifications`);
    return response;
}

// update read state of one notification
export const updateOneNotificationReadStatus = async (notification_id) => {
    const response = await patchData(`/users/notifications/${notification_id}/mark-read`);
    return response;
}

// update read state of all notifications
export const updateAllNotificationReadStatus = async () => {
    const response = await patchData(`/users/notifications/mark-all-read`);
    return response;
}

// update read state of all notifications
export const updateProject = async (project_id,payload) => {
    const response = await putData(`/projects/${project_id}`,payload);
    return response;
}

// delete project by project id
export const deleteProject = async (project_id) => {
    const response = await deleteData(`projects/${project_id}`);
    return response;
}

// create new initiative
export const createInitiative = async (payload) => {
    const response = await postData(`initiatives/`,payload);
    return response;
}

// add member to project as admin or contributor
export const addMemberToProject = async (project_id,payload) => {
    const response = await putData(`projects/${project_id}/members`,payload);
    return response;
}

// remove member from the project 
export const removeMemberFromProject = async (project_id,user_id) => {
    const response = await deleteData(`projects/${project_id}/members/${user_id}`);
    return response;
}


// delete initiative by initiative id
export const deleteInitiative = async (initiative_id) => {
    const response = await deleteData(`initiatives/${initiative_id}`);
    return response;
}

// Get all project tasks
export const getAllProjectTasks = async (project_id, priority, assignee) => {
    const response = await getData(`/tasks/?project_id=${project_id}&priority=${priority}&assignee=${assignee}`);
    return response;
}

// delete task by task id
export const deleteTask = async (task_id) => {
    const response = await deleteData(`/tasks/${task_id}/`);
    return response;
}

// update task by task id
export const createTask = async (payload) => {
    const response = await postData(`/tasks/`,payload);
    return response;
}

// update task by task id
export const updateTask = async (task_id, payload) => {
    const response = await putData(`/tasks/${task_id}/`,payload);
    return response;
}

// delete task by task id
export const deleteSubTask = async (task_id, subtask_id) => {
    const response = await deleteData(`/tasks/${task_id}/subtask/${subtask_id}/`);
    return response;
}

// update task by task id
export const createSubTask = async (task_id, payload) => {
    const response = await postData(`/tasks/${task_id}/subtask/`,payload);
    return response;
}

// update task by task id
export const updateSubTask = async (task_id, subtask_id, payload) => {
    const response = await putData(`/tasks/${task_id}/subtask/${subtask_id}/`,payload);
    return response;
}


// Get all task comments
export const getAllTaskComments = async (id) => {
    return await getData(`/tasks/comments/${id}`);
}

// Add comment
export const createTaskComment = async (id, payload) => {
    return await postData(`/tasks/comments/${id}`,payload);
}
// Add comment
export const changeTaskState = async (payload) => {
    return await patchData(`/tasks/status`,payload);
}

// list all paper statuses
export const getPaperStatuses = async () => {
    return await getData(`/papers/status/`);
}

// update paper status
export const updatePaperStatus = async (paperId,statusId,name) => {
    return await patchData(`/papers/${paperId}/status/`,{status_id:statusId, status:name});
}