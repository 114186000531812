import React, { useEffect, useState } from "react";
import {
  deletePaper,
  getMyAllPapers,
  getUserActivities,
  getUserProfile,
} from "../api/Api";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CustomButton from "./CustomButton";
import PaperCard from "./cards/PaperCard";
import DeleteModel from "./modals/DeleteModel";
import avatar from "../assets/u_avatar.png";
import CustomActivityCalender from "./CustomActivityCalender";
import { capitalize } from "@mui/material";
import PaperCardSkeleton from "./skeleton/PaperCardSkeleton";
import CustomSkeleton from "./CustomSkeleton";

function UserProfileComponent() {
  const [userData, setUserData] = useState(null);
  const [papers, setPapers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteModelPaperId, setDeleteModelPaperId] = useState(null); // paper or initiative
  const [contributions, setContributions] = useState(null);
  const [open, setOpen] = useState(false);
  const openAlert = () => setOpen(true); // for open initiative delete model
  const closeAlert = () => setOpen(false); // for close initiative delete model

  const handleMenuItemClick = async (item, value) => {
    setDeleteModelPaperId(value);
    openAlert();
  };

  const handleDeletePaper = (paperId) => {
    console.log(paperId);
    deletePaper(paperId).then((response) => {
      if (response.status === 200)
        setPapers(papers.filter((paper) => paper.id !== paperId));
      else alert(response.message);
    });
    closeAlert();
  };

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);

      getUserProfile()
        .then((response) => {
          if (response.status === 200) {
            setUserData(response.data.data);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => setError(error.message));

      getUserActivities()
        .then((response) => {
          if (response.status === 200) {
            console.log("activities", response.data);
            setContributions(response.data);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => setError(error.message));

      getMyAllPapers()
        .then((response) => {
          if (response.status === 200) {
            console.log("papers", response.data);
            setPapers(response.data);
          } else {
            setError(response.message);
          }
        })
        .catch((error) => setError(error.message))
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const fullName = `${userData?.firstname || ""} ${userData?.lastname || ""}`;

  if (error) return <div>Error: {error}</div>;

  return (
    <section id="user-profile-component" className="container mt-5">
      {/* Profile details */}
      <div className="w-100 p-3 border-bottom border-color-secondary">
        <div className="d-flex align-items-start">
          {/* Profile Image */}
          <div className="d-block d-lg-flex">
            <div>
              <img
                src={avatar || "https://picsum.photos/id/64/170/170"}
                alt={`${userData?.username}'s profile`}
                className="rounded-circle"
                width="170"
                height="170"
              />
            </div>

            {/* User Information */}
            <div className="mx-lg-4 mt-3 mt-lg-0 d-flex flex-column user-details">
              <h3 className="mb-2 fw-bold text-capitalize">
                {loading ? (
                  <CustomSkeleton />
                ) : (
                  fullName
                )}
              </h3>

              {/* Job Title and Institute */}
              {loading ? (
                <CustomSkeleton />
              ) : (
                <div className="d-sm-inline-flex">
                  <p className="mb-2">
                    {userData?.jobTitle}
                    {" | "}
                    {userData?.institute?.name}
                  </p>
                </div>
              )}

              {/* Social Icons and Follower Info */}
              {loading ? (
                <CustomSkeleton />
              ) : (
                <div className="d-sm-inline-flex align-items-center mb-2 mb-sm-3">
                  <div className="d-flex gap-2 social-icon-container mb-2 mb-sm-0">
                    <GitHubIcon color="action" />
                    <LinkedInIcon color="action" />
                    <TwitterIcon color="action" />
                    <LanguageIcon color="action" />
                    <AlternateEmailIcon color="action" />
                  </div>
                  <p className="m-0">
                    <span className="mx-3 d-none d-sm-inline-flex">|</span>
                    <span className="text-color-tertiary me-2">
                      Followers:
                    </span>{" "}
                    78k <span className="mx-2">•</span>
                    <span className="text-color-tertiary mx-2">
                      Following:
                    </span>{" "}
                    54k
                  </p>
                </div>
              )}

              {/* Country and Last Seen Info */}
              <p className="text-color-tertiary mb-2 mb-sm-3 fs-6">
                {loading ? (
                  <CustomSkeleton className="w-50" />
                ) : (
                  userData?.country
                )}
              </p>
              {loading ? (
                <CustomSkeleton />
              ) : (
                <p className="text-color-tertiary fs-6">
                  Joined 2 months ago • Last seen yesterday
                </p>
              )}
            </div>
          </div>

          {/* More Options */}
          <div className="ms-auto ">
            <div className="fs-2">...</div>
          </div>
        </div>
        <CustomButton
          className="my-3 px-5"
          variant="custom-primary-outline-button"
          rounded
        >
          Follow
        </CustomButton>
      </div>

      {/* Paper details */}
      {/* <div className="pt-5 pe-4 d-flex flex-row-reverse">
          <CustomButton
            className={"ms-auto py-2"}
            rounded={true}
            onClick={() => {}}
            pre={<AddRoundedIcon sx={{ width: 20 }} />}
          >
            NEW ARTICLE
          </CustomButton>
        </div> */}

      <CustomActivityCalender values={contributions} />

      <div>
        {loading ? (
          <PaperCardSkeleton n={3} />
        ) : (
          papers?.map((paper) => (
            <PaperCard
              key={paper.id}
              loading={loading}
              paper={paper}
              handleMenuItemClick={handleMenuItemClick}
            />
          ))
        )}
      </div>
      <DeleteModel
        open={open}
        closeAlert={closeAlert}
        onClick={() => handleDeletePaper(deleteModelPaperId)}
        type="Paper"
      />
    </section>
  );
}

export default UserProfileComponent;
