import React from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import logoWithText from "../assets/logoWithText.png";

function Logo({ className, style, withText }) {
  const navigate = useNavigate();

  return (
    <Image
      style={{ cursor: "pointer",borderRadius: withText? "8px": "15px", ...style }}
      className={className}
      width={withText? 100: 40}
      height={40}
      onClick={() => navigate("/home")}
      src={withText? logoWithText: logo}
    />
  );
}

export default Logo;
