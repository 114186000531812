import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Dropzone from "dropzone";
import { showToast } from "../../utils/toastUtils";
import { Form } from "react-bootstrap";
import styles from "./UploadDropzone.module.css";
import { getJournals, uploadPaper } from "../../api/Api";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";

function UploadDropzone({ show, handleClose, initiativeId, projectId }) {
  const navigate = useNavigate();
  const dropzoneRef = useRef(null); // Ref for the Dropzone element
  const [uploadData, setUploadData] = useState(null);
  const [paperId, setPaperId] = useState(null);
  const [journals, setJournals] = useState([]);

  const dropzoneInstance = useRef(null); // Ref for the Dropzone instance

  const initializeDropzone = () => {
    if (dropzoneRef.current && !dropzoneInstance.current) {
      dropzoneInstance.current = new Dropzone(dropzoneRef.current, {
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/papers/upload/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Cache-Control": "",
        },
        acceptedFiles: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        addRemoveLinks: true,
        maxFiles: 1, // Limit to 1 file
        dictDefaultMessage: "Drag here",
        dictFallbackMessage:
          "Your browser does not support drag'n'drop file uploads.",
        init: function () {
          this.on("addedfile", function (file) {
            console.log("File added:", file);
          });
          this.on("success", function (file, response) {
            console.log("File uploaded successfully:", response);
            setUploadData(response.data);
            disableDropzone();
          });
          this.on("error", function (file, message) {
            file.previewElement.querySelector(".dz-error-message").textContent =
              "igdsidis";
            console.log("Failed to upload file:", message);
            disableDropzone();
          });
          this.on("removedfile", function (file) {
            console.log("File removed:", file);
            enableDropzone(); // Enable Dropzone to accept new file after removal
          });
        },
      });
    }
  };

  const disableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = false; // Disable the clickable option
      dropzoneInstance.current.disable(); // Disable Dropzone entirely
    }
  };

  const enableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = true; // Re-enable clickable option
      dropzoneInstance.current.enable(); // Enable Dropzone
    }
  };

  const handleUpload = async (values) => {
    if (!uploadData) {
      showToast("Please upload a file before proceeding.", "error");
      return;
    }

    const payload = {
      name: uploadData.file_name,
      description: "Description of the uploaded paper.",
      initiative_id: initiativeId,
      file_id: uploadData.file_hash,
      journal_id: values.journal_id,
    };
    
    uploadPaper(payload)
      .then((response) => {
        if (response.status === 200) {
          const paperId = response.data.id;
          setPaperId(paperId);
          navigate(
            `/project/${projectId}/initiative/${initiativeId}/journals/${values.journal_id}/preview/${paperId}?upload=true`
          );
        } else {
          showToast(response.data.message, "error");
          console.error("Error uploading data:", response);
        }
      })
      .catch((error) => {
        console.error("Upload failed:", error);
      });
  };

  useEffect(() => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable === false
        ? enableDropzone()
        : initializeDropzone();
    } else {
      initializeDropzone();
    }

    if (!show) setUploadData(null);

    return () => {
      if (dropzoneInstance.current) {
        dropzoneInstance.current.destroy();
        dropzoneInstance.current = null;
      }
    };
  }, [show, paperId]);

  useEffect(() => {
    getJournals().then((response) => {
      if (response.status === 200) {
        setJournals(response.data);
    }}).catch((error) => {
      console.error("Error fetching journals:", error);
      showToast(
        error.response?.data?.message || "Error fetching journals",
        "error"
      );
    });
  },[paperId]);

  const validationSchema = Yup.object({
    journal_id: Yup.string().required("Please select a journal"),
  });

  return (
    <Modal show={show} onHide={handleClose} className={styles.cdz}>
      <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
      <Modal.Body style={{ margin: "auto" }}>
      <Formik
          initialValues={{ journal_id: "" }}
          validationSchema={validationSchema}
          onSubmit={handleUpload}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="upload-form">
              <div ref={dropzoneRef} className="dropzone custom-dropzone">
                <div className="previews"></div>
              </div>

              <Form.Group controlId="dropdownSelect" className="mt-3">
                <Field
                  as="select"
                  name="journal_id"
                  className={styles["form-select"]}
                  onChange={(e) => setFieldValue("journal_id", e.target.value)}
                >
                  <option value="" disabled>
                    Choose the Journal here..
                  </option>
                  {journals?.map((journal) => (
                    <option key={journal.id} value={journal.id}>
                      {journal.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="journal_id"
                  component="div"
                  className="text-danger"
                />
              </Form.Group>

              <Modal.Footer
                className="justify-content-center border-0"
                style={{ marginBottom: "30px" }}
              >
                <CustomButton rounded={true} type="submit">
                  Upload
                </CustomButton>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default UploadDropzone;
