import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CustomButton from "../components/CustomButton";
import projectIcon from "../assets/codicon_project.png";
import initiativeIcon from "../assets/material-symbols-light_article-outline.png";
import { useNavigate, useParams } from "react-router-dom";
import AddNewInitiative from "../components/modals/AddNewInitiative";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { InitiativeContext } from "../contexts/InitiativeContextProvider";
import img from "../assets/emptybox.png";
import InitiativeCardSkeleton from "../components/skeleton/InitiativeCardSkeleton";
import ProjectSettingsForm from "../components/forms/ProjectSettingsForm";
import AddNewMember from "../components/modals/AddNewMember";
import Tasks from "./Tasks";
import {RAGStatus} from "../components/Status";

function Project() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [showAddInitiative, setShowAddInitiative] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [tab, setTab] = useState("initiatives");
  const [currentProjectId, setCurrentProjectId] = useState(projectId);
  const {
    data: project,
    error,
    loading,
    refresh,
  } = useContext(InitiativeContext);
  const closeAddInitiativeModel = () => setShowAddInitiative(false);
  const handleOpenAddInitiativeModel = () => setShowAddInitiative(true);

  const closeAddMemberModel = () => setShowAddMember(false);
  const handleOpenAddMemberModel = () => setShowAddMember(true);

  useEffect(() => {
    if (projectId !== currentProjectId) {
      setTab("initiatives");
      setCurrentProjectId(projectId);
    }
  }, [projectId, currentProjectId]);

  if (error) {
    return <div>{error}</div>;
  }

  const limitDescription = (description, charLimit = 200) => {
    if (description.length > charLimit) {
      return description.slice(0, charLimit) + "...";
    }
    return description;
  };

  function generateRandomId(min = 100, max = 600) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <section id="projects" className="p-4 p-md-5">
      <div className=" mb-4 mb-sm-0">
        <div className="d-lg-flex justify-content-between mb-3">
          <div className="d-flex">
            <img width={17} height={17} src={projectIcon} alt="project-icon" />{" "}
            <h6 className="px-2">{project?.name}</h6>
          </div>

          <div className="d-block text-end">
          {tab === "initiatives" ? (
            <CustomButton rounded={true} onClick={handleOpenAddInitiativeModel}>
              <AddRoundedIcon sx={{ width: 20 }} /> NEW INITIATIVES
            </CustomButton>
          ) : (
            <CustomButton rounded={true} onClick={handleOpenAddMemberModel}>
              <AddRoundedIcon sx={{ width: 20 }} /> ADD MEMBER
            </CustomButton>
          )}
          </div>
        </div>
      </div>

      <button
        className={`btn-home ${tab === "initiatives" ? "btn-home-focus" : ""}`}
        onClick={() => setTab("initiatives")}
      >
        Initiatives
      </button>
      <button
        className={`btn-home ${tab === "tasks" ? "btn-home-focus" : ""}`}
        onClick={() => setTab("tasks")}
      >
        Goals
      </button>
      <button
        className={`btn-home ${tab === "settings" ? "btn-home-focus" : ""}`}
        onClick={() => setTab("settings")}
      >
        Settings
      </button>

      {tab === "initiatives" ? (
        <div className="initiatives-grid">
          <h3 className="title-1 my-4 my-sm-5">
            <img width={17} src={initiativeIcon} alt="project-icon" /> My
            Initiatives
          </h3>
          <Row xs={1} sm={2} lg={3} xl={4}>
            {loading ? (
              <InitiativeCardSkeleton n={4} />
            ) : (
              project?.initiatives?.map((initiative, index) => (
                <Col key={index}>
                  <Card
                    onClick={() =>
                      navigate(
                        `/project/${projectId}/initiative/${initiative.id}/?name=${project?.name}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="border-0 border-radius-0"
                  >
                    <Card.Img
                      style={{
                        width: "100%",
                        height: "auto",
                        aspectRatio: "1.2/1",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                      variant="top"
                      src={
                        initiative.coverImage ||
                        `https://picsum.photos/id/${generateRandomId()}/600/900`
                      }
                    />
                    <Card.Body>
                      <Card.Title className="title-1">
                        {initiative.name}
                      </Card.Title>
                      <Card.Text
                        style={{ fontSize: "0.9rem" }}
                        className="py-2"
                      >
                        {limitDescription(initiative.description)}
                      </Card.Text>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                          <Card.Link
                              style={{ color: "#383838" }}
                              className="text-decoration-none"
                              href="#"
                          >
                              <strong>View</strong>
                          </Card.Link>
                          <RAGStatus type={'Critical'}/>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
            {project?.initiatives.length === 0 ? (
              <div className="w-100 d-flex flex-column align-items-center">
                <p className="text-center text-color-tertiary fst-italic fs-6 mb-0">
                  Currently, there are no initiatives to display.
                  <br /> You can add a new initiative by clicking on the ‘New
                  Initiative’ button above.
                </p>

                <img
                  src={img}
                  className="opacity-25"
                  alt=""
                  style={{ width: "150px" }}
                />
              </div>
            ) : (
              ""
            )}
          </Row>
        </div>
      ) : tab === "settings" ? (
        <ProjectSettingsForm
          loading={loading}
          project={project}
          projectId={projectId}
          refreshInitiative={refresh}
        />
      ) : (
        <Tasks
          loading={loading}
          project={project}
          projectId={projectId}
          refreshInitiative={refresh}
        />
      )}
      <AddNewInitiative
        show={showAddInitiative}
        handleClose={closeAddInitiativeModel}
        project_id={projectId}
      />
      <AddNewMember
        show={showAddMember}
        handleClose={closeAddMemberModel}
        project_id={projectId}
      />
    </section>
  );
}

export default Project;
