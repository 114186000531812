import React from "react";
import { Modal } from "react-bootstrap";
import "../../styles/reviewer/Components.css";
import CustomButton from "../CustomButton";
import "./DialogBox.module.css"; // Import css modules stylesheet as styles
import lineImage from "../../assets/Group 280.png";

export default function TextModel({
  open,
  setClose, // model close
  bodyText = "Paper was marked as ready for submission",
  leftButtonTitle = "Ok",
}) {
  return (
    <Modal
      style={{ top: "10%" }}
      className="dialog-box"
      show={open}
      onHide={setClose}
    >
      <Modal.Header closeButton style={{ borderBottom: 0 }}></Modal.Header>
      <Modal.Body className="text-center">
        <p >{bodyText}</p>
        <img src={lineImage} alt="line" className="w-75" />
      </Modal.Body>
      <div className="text-center mb-4">
      <CustomButton onClick={setClose}>{leftButtonTitle}</CustomButton>
      </div>
    </Modal>
  );
}
