import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SideMenu from "../components/SideMenu";
import ProjectContextProvider from "../contexts/ProjectContextProvider";

//layout with upper navigation bar and the side bar
const MainLayout = () => {

  
  return (
    <ProjectContextProvider>
      <NavigationBar />
          <Row style={{minHeight:"80vh"}} className="mx-0">
              <Col className="d-none d-md-block" sm={4} lg={3} style={{maxWidth:'300px'}}><aside><SideMenu /></aside></Col>
              <Col className="border-start" sm={12} md={8} lg={9} ><main><Outlet /></main></Col>
          </Row>
      <Footer />
    </ProjectContextProvider>
  );
};

export default MainLayout;
