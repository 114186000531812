import React, { createContext } from "react";
import useFetch from "../hooks/useFetch";

const PaperContext = createContext(null);

function PaperContextProvider({ children, paperId }) {
    const { data, error, loading, refresh } = useFetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/papers/${paperId}`
    );
  return (
    <PaperContext.Provider value={{ data, error, loading, refresh }}>
      {children}
    </PaperContext.Provider>
  )
}

export default PaperContextProvider
export { PaperContext };