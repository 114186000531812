import React, {useEffect, useState} from "react";
import {FaPlus} from "react-icons/fa";
import {MdOpenInNew} from "react-icons/md";
import {Avatar, Checkbox, LinearProgress, Tooltip} from "@mui/material";
import styles from "./table.module.css";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import {IoAdd, IoEllipsisVertical} from "react-icons/io5";
import {changeTaskState, deleteSubTask, deleteTask, getAllProjectTasks} from "../../api/Api";
import AddUpdateTask from "../modals/AddUpdateTask";
import AddUpdateSubTask from "../modals/AddUpdateSubTask";
import {showToast} from "../../utils/toastUtils";
import Drawer from "@mui/material/Drawer";
import TaskComments from "../TaskComments";

const TaskTable = ({project_id, priority, assignee}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [openTaskCreateUpdate, setOpenTaskCreateUpdate] = useState(false);
    const [openSubTaskCreateUpdate, setOpenSubTaskCreateUpdate] = useState(false);
    const [openComments, setOpenComments] = useState(false);
    const [taskPopupOptions, setTaskPopupOptions] = useState({
        is_new: true,
        task: {},
        subTask: {}
    });
    const [commentSectionOptions, setCommentSectionOptions] = useState({
        is_sub: false,
        id: 0,
        title: ""
    });
    const [isFiltered, setIsFiltered] = useState(false);

    useEffect(() => {

        setIsFiltered(Boolean(assignee || priority))

        loadAllProjectTasks()
    }, [assignee, priority]);

    const loadAllProjectTasks = () => {
        getAllProjectTasks(project_id, priority, assignee).then(res => {
            setTasks(res.data)
        })
    }

    const setTaskPopupOption = (updatedOptions) => {
        setTaskPopupOptions((prevOptions) => ({
            ...prevOptions,
            ...updatedOptions
        }));
    };

    const openAddUpdateTaskPopup = async (is_new = true, task) => {
        await setTaskPopupOption({is_new, task: task});
        setOpenTaskCreateUpdate(true)
    }
    const openAddUpdateSubTaskPopup = async (is_new = true, task, subTask) => {
        await setTaskPopupOption({is_new, task: task, subTask: subTask});
        setOpenSubTaskCreateUpdate(true)
    }

    const toggleCommentSection = (is_sub = false, id = 0, title = "") => {
        console.log(title)
        console.log(id)
        setCommentSectionOptions((prevOptions) => ({
            ...prevOptions,
            id: id,
            is_sub: is_sub,
            title: title
        }));
        setOpenComments(!openComments)
    }

    // generate a random color for each user
    const stringToColor = (string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ('00' + value.toString(16)).slice(-2);
        }
        return color;
    };

    const checkTaskAvailableToMark = (row) => {
        for (let item of row.subtasks) {
            if (!item.is_completed) {
                return {
                    disabled: true
                };
            }
        }

        return {
            disabled: false
        };
    }

    const markAsDone = (item, isMainTask) => {
        const obj = {
            id: item.id,
            completed: !item.is_completed
        }

        changeTaskState(obj).then(res => {
            if (res.status === 200) {
                const statusMessage = obj.completed ? "done" : "in progress";
                const name = isMainTask ? 'Goal' : 'Sub task';
                showToast(`${name} was marked as ${statusMessage}`, "success")
                loadAllProjectTasks()
            }
        })
    }

    const getProgress = (progress, is_completed) => {
        const [numerator, denominator] = progress.split('/').map(Number);


        if (denominator === 0 && is_completed) {
            return 100;
        }

        if (denominator === 0) {
            return 0;
        }

        return (numerator / denominator) * 100;
    }

    const setSubtask = (task) => {
        openAddUpdateSubTaskPopup(true, task, {})
    }

    return (
        <div>
            <div className="py-2 mb-3">
                <div onClick={openAddUpdateTaskPopup}
                     className={`btn d-flex align-items-center text-white ${styles.newTaskButton}`}>
                    <IoAdd color="#fff"/> New Goal
                </div>
            </div>
            <div className={`${styles.tableWrapper} ${styles.styledScroll}`}>
                <div className={styles.tableContainer}>
                    {/* Table Header */}
                    <div className={styles.tableHeader}>
                        <div className={`${styles.headerCell} ${styles.task}`}>Title</div>
                        <div className={`${styles.headerCell} ${styles.assignee}`}>Assignee</div>
                        <div className={`${styles.headerCell} ${styles.priority}`}>Priority</div>
                        <div className={`${styles.headerCell} ${styles.progress}`}>Progress</div>
                        <div className={`${styles.headerCell} ${styles.comments}`}>Comments</div>
                        <div className={`${styles.headerCell} ${styles.actions}`}>Actions</div>
                    </div>

                    {/* Table Rows */}
                    {tasks?.map((row) => (
                        <div className="mb-5" style={{cursor: "pointer"}} key={row.id}>
                            <div className={styles.tableRow}>
                                <div className={`${styles.cell} ${styles.task}`}>
                                    <Checkbox
                                        {...checkTaskAvailableToMark(row)}
                                        type="checkbox"
                                        checked={row.is_completed}
                                        size="small"
                                        onClick={() => markAsDone(row, true)}
                                        sx={{
                                            color: '#333333',
                                            '&.Mui-checked': {
                                                color: '#3CAAC4',
                                            }
                                        }}
                                    />

                                    <span onClick={() => openAddUpdateTaskPopup(false, row)}
                                          className={`ms-3 ${styles.truncateText}`}>{row.title}</span>
                                </div>

                                <div className={`${styles.cell} ${styles.assignee}`}>
                                    {row.assignee
                                        ?
                                        <Tooltip title={`${row.assignee?.first_name} ${row.assignee?.last_name}`} arrow
                                                 placement="top">
                                            <Avatar
                                                alt={`${row.assignee?.first_name} ${row.assignee?.last_name}`}
                                                src={" "}
                                                sx={
                                                    {
                                                        fontSize: 14,
                                                        width: 30,
                                                        height: 30,
                                                        bgcolor: row.assignee.profile_picture ? 'transparent' : stringToColor(row.assignee.first_name),
                                                    }
                                                }
                                            />
                                        </Tooltip>
                                        : ''}
                                </div>

                                <div className={`${styles.cell} ${styles.priority}`}>
                                    {row.priority ? row.priority : '-'}
                                </div>

                                <div className={`${styles.cell} ${styles.progress}`} style={{padding: "0.5rem 0"}}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={getProgress(row.progress, row.is_completed)}
                                        sx={{
                                            height: 13,
                                            borderRadius: 10,
                                            width: "70%",  // Reduce width slightly for better fit within the cell
                                            backgroundColor: "rgba(60, 170, 196, 0.2)",
                                            "& .MuiLinearProgress-bar": {
                                                backgroundColor: "#3CAAC4",
                                            },
                                        }}
                                    />
                                </div>


                                <div className={`${styles.cell} ${styles.comments}`}
                                     onClick={() => toggleCommentSection(false, row.id, row.title)}
                                >
                                    <div className={styles.iconLink}>
                                        {row.commentsCount} <MdOpenInNew
                                        style={{color: 'rgba(56, 56, 56, 0.4)', fontSize: '15'}}/>
                                    </div>
                                </div>

                                <div className={`${styles.cell} ${styles.actions}`}>
                                    <div className={styles.actionIcons}>
                                        <button
                                            onClick={() => openAddUpdateSubTaskPopup(true, row)}
                                            className={styles.plusButton}>
                                            <FaPlus size={13}/>
                                        </button>
                                        <ActionDropdown is_sub_task={false} taskId={row.id}
                                                        loadAll={loadAllProjectTasks}/>
                                    </div>
                                </div>
                            </div>

                            {row.subtasks && row.subtasks.length > 0 ? (
                                row.subtasks.map((subRow) => (
                                    <div key={subRow.id} className={styles.tableRow}>
                                        <div className={`${styles.cell} ${styles.task} ${styles.subRow}`}>
                                            <Checkbox
                                                type="checkbox"
                                                size="small"
                                                checked={subRow.is_completed}
                                                onClick={() => markAsDone(subRow, false)}
                                                sx={{
                                                    color: '#333333',
                                                    '&.Mui-checked': {
                                                        color: '#3CAAC4',
                                                    }
                                                }}
                                            />

                                            <span onClick={() => openAddUpdateSubTaskPopup(false, row, subRow)}
                                                  className={`ms-3 ${styles.truncateText}`}>{subRow.title}</span>
                                        </div>

                                        <div className={`${styles.cell} ${styles.assignee}`}>
                                            {subRow.assignee
                                                ? <Tooltip
                                                    title={`${subRow.assignee?.first_name} ${subRow.assignee?.last_name}`}
                                                    arrow placement="top">
                                                    <Avatar
                                                        alt={`${subRow.assignee?.first_name} ${subRow.assignee?.last_name}`}
                                                        src={" "}
                                                        sx={
                                                            {
                                                                fontSize: 14,
                                                                width: 30,
                                                                height: 30,
                                                                bgcolor: subRow.assignee.profile_picture ? 'transparent' : stringToColor(subRow.assignee.first_name),
                                                            }
                                                        }
                                                    />
                                                </Tooltip>

                                                : ''}
                                        </div>

                                        <div className={`${styles.cell} ${styles.priority}`}>
                                            {subRow.priority ? subRow.priority : '-'}
                                        </div>

                                        <div className={`${styles.cell} ${styles.progress}`}></div>

                                        <div className={`${styles.cell} ${styles.comments}`}>
                                            <div className={styles.iconLink}
                                                 onClick={() => toggleCommentSection(true, subRow.id, subRow.title)}>
                                                {subRow.commentsCount} <MdOpenInNew
                                                style={{color: 'rgba(56, 56, 56, 0.4)', fontSize: '15'}}/>
                                            </div>
                                        </div>

                                        <div className={`${styles.cell} ${styles.actions}`}>
                                            <div className={styles.actionIcons}>
                                                <ActionDropdown
                                                    is_sub_task={true}
                                                    taskId={row.id}
                                                    subtaskId={subRow.id}
                                                    loadAll={loadAllProjectTasks}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))) : (
                                <div className={styles.noSubtasksMessage}>{isFiltered ? '' : 'No subtask'}</div>
                            )}
                        </div>
                    ))}
                </div>

                {openTaskCreateUpdate
                    ? <AddUpdateTask
                        projectId={project_id}
                        show={openTaskCreateUpdate}
                        task={taskPopupOptions.task}
                        setSubtask={setSubtask}
                        loadAll={loadAllProjectTasks}
                        is_new_task={taskPopupOptions.is_new}
                        handleClose={() => setOpenTaskCreateUpdate(false)}
                    />
                    : ""}
                {openSubTaskCreateUpdate
                    ? <AddUpdateSubTask
                        projectId={project_id}
                        task={taskPopupOptions.task}
                        loadAll={loadAllProjectTasks}
                        show={openSubTaskCreateUpdate}
                        subTask={taskPopupOptions.subTask}
                        is_new_task={taskPopupOptions.is_new}
                        handleClose={() => setOpenSubTaskCreateUpdate(false)}
                    />
                    : ""}

            </div>

            <Drawer
                anchor="right"
                open={openComments}
                onClose={toggleCommentSection}
                classes={{paper: styles.drawerContent}}
            >
                <TaskComments
                    close={toggleCommentSection}
                    loadAll={loadAllProjectTasks}
                    stringToColor={stringToColor}
                    options={commentSectionOptions}
                />

            </Drawer>
        </div>
    );
};

export default TaskTable;

export const ActionDropdown = ({is_sub_task, taskId, subtaskId, loadAll}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteTaskOrSubTask = () => {
        if (is_sub_task) {
            deleteSubTask(taskId, subtaskId).then(res => {
                if (res.status === 200) {
                    showToast("Sub task is successfully deleted", "success")
                    loadAll()
                    handleClose()
                }
            })
        } else {
            deleteTask(taskId).then(res => {
                if (res.status === 200) {
                    showToast("Goal is successfully deleted", "success")
                    loadAll()
                    handleClose()
                }
            })
        }
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    minWidth: 0,
                    padding: 0,
                    '& svg': {
                        fontSize: '1.5rem',
                        color: '#333',
                    },
                }}
            >
                <IoEllipsisVertical color={"#777"}/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={deleteTaskOrSubTask}>Delete {is_sub_task ? 'Sub Task' : 'Goal'}</MenuItem>
            </Menu>
        </div>
    );
}