import React from "react";
import "../styles/Home.css";

// variants are normally bootstrap btn classes and can be also use custom-primary-outline-button
const CustomButton = ({
  style,
  children,
  onClick,
  className,
  rounded,
  pre,
  type,
  variant = "custom-btn",
}) => {
  let classX = "";
  if (rounded) {
    classX = "rounded-pill";
  }

  return (
    <button
      type={type}
      onClick={onClick}
      style={style}
      className={`btn ${variant} font-weight-bold text-nowrap ${classX} ${className}`}
    >
      {pre && <span className="h5 m-0">{pre}</span>}
      {children}
    </button>
  );
};

export default CustomButton;
