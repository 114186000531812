import React, { useContext, useEffect, useState } from "react";
import MemberList from "../MemberList";
import DialogBox from "../modals/DialogBox";
import { deleteProject, removeMemberFromProject, updateProject } from "../../api/Api";
import { showToast } from "../../utils/toastUtils";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../contexts/ProjectContextProvider";
import CustomSkeleton from "../CustomSkeleton";
import { useFormik } from "formik";
import { ProjectUpdateSchema } from "../../utils/validations";

const ProjectSettingsForm = ({
  project,
  loading,
  projectId,
  refreshInitiative,
}) => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const { refresh } = useContext(ProjectContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openLeave, setOpenLeave] = useState(false);
  const formik = useFormik({
    initialValues: {
      projectName: projectName || "",
      projectDescription: projectDescription || "",
    },
    validationSchema: ProjectUpdateSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      handleSubmit(values);
    },
  });
  const getUserInfo = () => JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    console.log(project);
    if (project) {
      setProjectName(project.name);
      setProjectDescription(project.description);
    }
  }, [project]);

  const openAlert = () => setOpen(true);
  const closeAlert = () => setOpen(false);

  const handleSubmit = async (values) => {
    // e.preventDefault();
    if (!projectName.trim()) {
      showToast("Project Name is required.", "error");
      return;
    }

    if (
      values.projectName === project.name &&
      values.projectDescription === project.description
    ) {
      return;
    }

    const payload = {
      name: values.projectName,
      description: values.projectDescription,
    };

    updateProject(projectId, payload)
      .then((response) => {
        if (response.status === 200) {
          refreshInitiative();
          refresh();
          console.log("Project updated successfully");
        }
      })
      .catch((error) => {
        showToast(
          error.response?.data?.message || "Error loading data",
          "error"
        );
        console.log(error);
      });
  };

  const handleDelete = () => {
    deleteProject(projectId).then((response) => {
      if (response.status === 200) {
        refresh();
        navigate("/home");
      }
    });
  };

  const handleProjectDelete = () => openAlert();

  const handleRemoveMember = (user_id,project_id) => {
    // check if user is removing himself
    const userInfo = getUserInfo();
    if (user_id === userInfo.id) {
      setOpenLeave(true);
      return;
    }

    removeMemberFromProject(project_id,user_id).then(response => {
      if (response.status === 200) {
        showToast("Member removed successfully", "success")
        refreshInitiative();
      };
    }).catch(error => {
      console.log(error)
    })
  }

  const closeAlertLeave = () => setOpenLeave(false);
  const handleLeaveProject = () => {
    const userInfo = getUserInfo();
    removeMemberFromProject(projectId,userInfo.id).then(response => {
      if (response.status === 200) {
        showToast("Left project successfully", "success")
        refresh();
        navigate("/home");
      };
    }).catch(error => {
      console.log(error)
    })
  }
  
  return (
    <>
      <div
        id="project-settings"
        className="border w-100 my-3 my-lg-4 my-xl-5 px-4 py-5"
      >
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="projectName" className="form-label">
            <h5>Name</h5>
          </label>
          <input
            type="text"
            className={`form-control p-2 p-lg-3 ${
              formik.touched.projectName && formik.errors.projectName
                ? "is-invalid"
                : ""
            }`}
            placeholder="Project Name"
            {...formik.getFieldProps("projectName")}
          />
          {formik.touched.projectName && formik.errors.projectName && (
            <div className="invalid-feedback">{formik.errors.projectName}</div>
          )}

          <label htmlFor="projectDescription" className="form-label mt-4">
            <h5>Description</h5>
          </label>
          <input
            type="text"
            className={`form-control p-2 p-lg-3 ${
              formik.touched.projectDescription &&
              formik.errors.projectDescription
                ? "is-invalid"
                : ""
            }`}
            placeholder="Project Description"
            {...formik.getFieldProps("projectDescription")}
          />
          {formik.touched.projectDescription &&
            formik.errors.projectDescription && (
              <div className="invalid-feedback">
                {formik.errors.projectDescription}
              </div>
            )}

          <button
            type="submit"
            className="btn custom-primary-outline-button rounded-0 px-3 mt-4"
          >
            Update
          </button>
          <button
            type="button"
            className="btn btn-outline-danger rounded-0 px-3 mx-3 mt-4"
            onClick={() => handleProjectDelete(projectId)}
          >
            Delete Project
          </button>
        </form>
      </div>

      {/* make this dynamic list */}
      <section>
        <h5>Members </h5>
        {!loading ? (
          <MemberList list={project?.members} removeMember={(e)=>handleRemoveMember(e,projectId)}/>
        ) : (
          <CustomSkeleton className="p-2 mb-2" count={3} />
        )}
      </section>

      <DialogBox
        open={open}
        setClose={closeAlert}
        bodyText={
          <span>
            Once deleted, this{" "}
            <strong>project will be permanently removed </strong> and cannot be
            recovered. Are you sure you want to delete?
          </span>
        }
        submitButtonTitle="Delete"
        submitButtonVariant="btn-danger"
        onClick={handleDelete}
      />

      <DialogBox
        open={openLeave}
        setClose={closeAlertLeave}
        bodyText={
          <span>
            Do you want to leave this project?
          </span>
        }
        submitButtonTitle="Yes"
        submitButtonVariant="btn-danger"
        onClick={handleLeaveProject}
      />

    </>
  );
};


export default ProjectSettingsForm;
