import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import React, {useContext, useEffect, useState} from "react";
import {useFormik} from "formik";
import {TaskCreateUpdateSchema} from "../../utils/validations";
import {Col, Row} from "react-bootstrap";
import {Autocomplete, TextField} from "@mui/material";
import modelStyles from "../../styles/custom-model.module.css"
import {InitiativeContext} from "../../contexts/InitiativeContextProvider";
import {createSubTask, updateSubTask} from "../../api/Api";
import {showToast} from "../../utils/toastUtils";

const priorities = [
    {name:"High", value:"High"},
    {name:"Medium", value:"High"},
    {name:"Low", value:"High"}
]

const dropdownStyles ={
    "& .MuiOutlinedInput-root": {
        height: "40px",
    }
}

function AddUpdateSubTask({show, handleClose, subTask, task, is_new_task = true, loadAll}) {
    const { refresh, data, loading } = useContext(InitiativeContext);
    const [assignees, setAssignees] = useState([]);
    const [closePopup, setClosePopup] = useState(true);
    const formik = useFormik({
        initialValues: {
            title: is_new_task ? "" : subTask.title,
            priorities: !is_new_task && subTask.priority ? {name:subTask.priority} : null,
            assignee: "",
        },
        validationSchema: TaskCreateUpdateSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmit(values)
        },
    });

    const handleSubmit = (values) => {
        if (is_new_task) {
            let obj = {
                title: values.title,
                priority: values.priorities ? values.priorities.name : null,
                assignee: values.assignee ? values.assignee.id : null
            }

            createSubTask(task.id, obj).then(res => {
                if (res.status === 200) {
                    showToast("Sub Task Added Successfully", "success")
                    clearFieldValues()
                    loadAll()
                    if (closePopup) handleClose()
                }
            })
        } else {
            let obj = {
                title: values.title,
                priority: values.priorities ? values.priorities.name : null,
                assignee: values.assignee ? values.assignee.id : null
            }
            updateSubTask(task.id, subTask.id, obj).then(res => {
                if (res.status === 200) {
                    showToast("Sub Task Updated Successfully", "success")
                    loadAll()
                    handleClose()
                }
            })

        }
    }

    useEffect(() => {
        if (data.members) {
            setAssignees(data.members)
        }

        if (subTask && !is_new_task && subTask.assignee && subTask.assignee.id) {
            const matchedUser = data.members.find((item) => item.id === subTask.assignee.id);

            if (matchedUser) {
                formik.setFieldValue("assignee", matchedUser);
            }
        }

    }, []);


    const clearFieldValues = () => {
        formik.setFieldValue("title", "");
        formik.setFieldValue("assignee", null);
        formik.setFieldValue("priorities", null);
    }

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <form onSubmit={formik.handleSubmit}
                  id="create-update-task-form"
                  className=""
            >
                <Modal.Header style={{borderBottom: 0}} closeButton></Modal.Header>
                <Modal.Title style={{textAlign: "center", marginBottom: "20px"}}>
                    {is_new_task ? "Add Subtask" : "Update Subtask"}
                </Modal.Title>
                <Modal.Body>

                    <Row className="mb-4">
                        <span className="mb-1 text-color-tertiary">Goal title:</span>
                        <h3>{task.title}</h3>
                    </Row>
                    <Row className="mb-4" >
                        <Col md={12}>

                            <label htmlFor="email" className="form-label text-color-tertiary">
                                <p className="small mb-0">Subtask title</p>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Subtask title"
                                name="title"
                                className={`${modelStyles.fields} form-control ${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                {...formik.getFieldProps('title')}
                            />
                            {formik.touched.title && formik.errors.title && (
                                <div className="invalid-feedback">{formik.errors.title}</div>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4" >
                        <Col md={6}>
                            <label htmlFor="priorities" className="form-label text-color-tertiary">
                                <p className="small mb-0">Priorities</p>
                            </label>
                            <Autocomplete
                                className="py-0"
                                disablePortal
                                options={priorities}
                                value={formik.values.priorities}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={
                                            formik.touched.priorities && Boolean(formik.errors.priorities)
                                        }
                                        helperText={formik.touched.priorities && formik.errors.priorities}
                                        sx={dropdownStyles}
                                        placeholder="Select priority"
                                    />
                                )}
                                onChange={(event, value) =>
                                    formik.setFieldValue("priorities", value)
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <label htmlFor="priorities" className="form-label text-color-tertiary">
                                <p className="small mb-0">Assignees</p>
                            </label>
                            <Autocomplete
                                className="py-0"
                                disablePortal
                                options={assignees}
                                value={formik.values.assignee}
                                getOptionLabel={(option) => option?.name || ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={
                                            formik.touched.assignee && Boolean(formik.errors.assignee)
                                        }
                                        helperText={formik.touched.assignee && formik.errors.assignee}
                                        sx={dropdownStyles}
                                        placeholder="Select assignee"
                                    />
                                )}
                                onChange={(event, value) =>
                                    formik.setFieldValue("assignee", value)
                                }
                            />
                        </Col>

                    </Row>

                </Modal.Body>
                <Modal.Footer className="border-0" style={{marginBottom: "30px"}}>

                    <button
                        onClick={()=>setClosePopup(false)}
                        type="submit"
                        className={`${is_new_task ? '' : 'd-none'} btn btn-outline-danger ${modelStyles.customSecondaryOutlineBtn} font-weight-bold rounded-pill`}
                    >
                        Save & Add Subtask
                    </button>

                    <CustomButton onClick={()=>setClosePopup(true)} rounded={true} type="submit">
                        Save
                    </CustomButton>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default AddUpdateSubTask;
