import React from 'react'
import { ImBin } from "react-icons/im";
import { ImExit } from "react-icons/im";

// this component is used to display a list of members in projects
const MemberList = ({ list = [], removeMember }) => {

    return (
      <>
        {list.map((member,index) => (
          <MemberCard
            key={member.id}
            id={member.id}
            index={index}
            name={member.name}
            role={member.role}
            onDelete={removeMember}
          />
        ))}
      </>
    );
  };
  
  export default MemberList

  
//   This is the child component of MemberList show one member card
  const MemberCard = ({ id, name, role, onDelete, index }) => {
    return (
      <div id={id} className="d-grid border-bottom py-3 mb-2 w-100">
        <div className="row align-items-center">
          <div className="col text-secondary">{name}</div>
          <div className="col text-center text-secondary">{role}</div>
          <div className="col text-end" onClick={() => onDelete && onDelete(id)}>
            {index > 0 ? <ImBin color="red" size={18} style={{ cursor: "pointer" }} />:
            <ImExit color="gray" size={18} style={{ cursor: "pointer" }} />}
          </div>
        </div>
      </div>
    );
  };

