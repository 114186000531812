import React from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../CustomButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

function ChangePaperStatus({ show, handleClose, list = [], handleSave }) {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    statusId: Yup.string().required("Status is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      statusId: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSave(values.statusId);
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        <Modal.Title style={{ textAlign: "center", marginBottom: "20px" }}>
          Change the Paper Status
        </Modal.Title>
        <Modal.Body className="d-flex justify-content-center">
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 350 }}
            error={formik.touched.statusId && Boolean(formik.errors.statusId)}
          >
            <InputLabel id="status-select-label">Select Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              name="statusId"
              value={formik.values.statusId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {list.sort((a, b) => a.order - b.order).map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.statusId && formik.errors.statusId && (
              <FormHelperText>{formik.errors.statusId}</FormHelperText>
            )}
          </FormControl>
        </Modal.Body>
        <Modal.Footer
          className="justify-content-center border-0"
          style={{ marginBottom: "30px" }}
        >
          <CustomButton className="w-75" rounded={true} type="submit">
            Save
          </CustomButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ChangePaperStatus;
